<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="loading || transaction==null">
      <span class="loader"></span>
    </span>
    <div v-if="!loading && transaction!=null" class="elite-tabs-wrapper mb-5">
      <div class="transaction">
        <h3>
          <span v-if="transaction.type == DEPOSIT">{{
            $t("WALLET.DEPOSIT_DEMAND")
          }}</span>
          <span v-else>{{ $t("WALLET.WITHDRAW_DEMAND") }}</span>
        </h3>
        <div class="row">
          <div class="col-12">
            <div class="balance-badge details-amount d-flex align-items-center">
              <in-out-badge :type="transaction.type" class="mr-2" />
              <amount-badge :amount="transaction.amount" />
            </div>
          </div>
          <div class="col-12 mt-3 details-table">
            <object-details :data="transaction_info" />
          </div>
        </div>
      </div>
      <div class="paymentmethod" v-if="paymentMethodDetails">
        <el-divider />
        <h3>{{ $t("WALLET.PAYMENTMETHOD") }}</h3>
        <div class="mt-3 details-table">
          <object-details :data="paymentMethodDetails" />
        </div>
      </div>
      <div class="actions" v-if="showOptions && transaction.meta.status !== STATUS_COMPLETED">
        <el-divider />
        <h3>{{ $t("WALLET.APPROVAL") }}</h3>
        <div class="align-items-center">
          <el-button
            v-if="approVableStatus.includes(transaction.meta.status)"
            type="primary"
            class="align-items-center my-1"
            round
            :disabled="loading"
            @click="updateTransactionStatus(STATUS_PENDING)"
          >
            <i class="fas fa-spinner fa-spin" v-if="loading" />
            {{ $t("WALLET.VALIDATE") }}
          </el-button>
          <el-button
            type="danger"
            class="my-1"
            @click="openDeclineForm = !openDeclineForm"
            round
            :disabled="loading"
          >
            {{ $t("WALLET.DECLINE") }}
          </el-button>
          <el-button
            type="success"
            class="align-items-center my-1"
            round
            :disabled="loading"
            @click="updateTransactionStatus(STATUS_COMPLETED)"
          >
            <i class="fas fa-spinner fa-spin" v-if="loading" />
            {{ $t("WALLET.TERMINATE_TRANSACTION") }}
          </el-button>
        </div>
        <div class="refuse-transanction-form" v-show="openDeclineForm">
          <div class="content-inputs">
            <el-divider />
            <el-form
              ref="refuseTransanction"
              :model="statusForm"
              label-position="left"
              @submit.prevent="updateTransactionStatus(STATUS_ADMIN_REFUSED)"
            >
              <div class="bank-refused">
                <el-checkbox v-model="bankRefused" border>{{
                  $t("WALLET.BANK_REFUSED")
                }}</el-checkbox>
              </div>
              <el-form-item :label="$t('WALLET.COMMENT')" prop="comment">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 3, maxRows: 4 }"
                  :placeholder="$t('WALLET.COMMENT')"
                  v-model="statusForm.comment"
                >
                </el-input>
              </el-form-item>
              <el-button
                type="primary"
                class="align-items-center mb-3"
                :disabled="loading"
                @click="updateTransactionStatus(STATUS_ADMIN_REFUSED)"
              >
                <i class="fas fa-spinner fa-spin" v-if="loading" />
                {{ $t("WALLET.SAVE") }}
              </el-button>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  DEPOSIT,
  WITHDRAW,
  POSSIBLE_HOLDER_ATTRIBUTES,
  STATUS_ADMIN_REFUSED,
  STATUS_PENDING,
  STATUS_COMPLETED,
  STATUS_DRAFT,
  STATUS_BANK_REFUSED,
} from "../constants/common";
import BalanceBadge from "./atoms/BalanceBadge.vue";
import AmountBadge from "./atoms/AmountBadge.vue";
import InOutBadge from "./atoms/InOutBadge.vue";
import ObjectDetails from "./atoms/ObjectDetails.vue";

export default {
  name: "ViewTransactionComponent",
  components: {
    InOutBadge,
    AmountBadge,
    ObjectDetails,
  },
  layout: "DashboardLayout",
  mixins: [requestErrorMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    showOptions:{
      type:Boolean,
      default:true
    }
  },
  watch: {
    id: {
      handler(val) {
        if (val) {
          this.get();
          this.clearForm();
        }
      },
      deep: true,
    },
  },

  data() {
    return {
      openDeclineForm: false,
      STATUS_PENDING: STATUS_PENDING,
      STATUS_ADMIN_REFUSED: STATUS_ADMIN_REFUSED,
      STATUS_COMPLETED: STATUS_COMPLETED,
      STATUS_BANK_REFUSED: STATUS_BANK_REFUSED,
      loading: false,
      transaction: null,
      DEPOSIT: DEPOSIT,
      WITHDRAW: WITHDRAW,
      transaction_info: null,
      paymentMethodDetails: null,
      POSSIBLE_HOLDER_ATTRIBUTES: POSSIBLE_HOLDER_ATTRIBUTES,
      statusForm: {
        status: "",
        comment: "",
      },
      bankRefused: false,
      approVableStatus:[
        STATUS_DRAFT,
        STATUS_ADMIN_REFUSED,
        STATUS_BANK_REFUSED
      ]
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        this.loading = true;
        const response = await this.$axios.get(
          `/elixio-wallet/transactions/${this.id}`
        );
        if (response.data.success) {
          this.transaction = response.data.data;
          this.transaction_info = {
            code: this.transaction.meta.code,
            label: this.transaction.meta.label,
            status: this.transaction.meta.status,
            COMMENT: this.transaction.meta.status_comment??'-',
            created_at: this.transaction.created_at,
            TRANSACTION_AUTHOR: this.transaction.meta.auth.name,
            CURRENT_WALLET_BALANCE:
              this.transaction.wallet.meta.currency_symbol +
              this.transaction.wallet.balance_float,
            WALLET_HOLDER: this.getWalletHolderName(
              this.transaction.wallet.holder
            ),
          };
          if (this.transaction.payment_method !== null) {
            let details = this.transaction.payment_method.details;
            this.paymentMethodDetails = {
              bank_name: details.bank_name,
              account_number: details.account_number,
              branch_code: details.branch_code,
              bank_address: details.bank_address,
              transit_number: details.transit_number,
              PAYMENT_METHOD_HOLDER: details.holder.name
            };
            this.statusForm.status = this.transaction.meta.status??'';
            this.bankRefused = this.transaction.meta.status==this.STATUS_BANK_REFUSED;
          }
        } else {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    clearForm() {
      this.statusForm = {
        status: "",
        comment: "",
      };
      this.bankRefused = false;
      this.openDeclineForm = false;
    },

    getWalletHolderName(holder) {
      if (!holder) return "-";
      let name = "";
      this.POSSIBLE_HOLDER_ATTRIBUTES.forEach((attr) => {
        if (holder[attr]) {
          if (name) {
            name += " ";
          }
          name += holder[attr];
        }
      });
      return name || "-";
    },

    async updateTransactionStatus(status) {
      if (status == this.STATUS_PENDING) {
        this.statusForm = {
          status: this.STATUS_PENDING,
        };
      } else if (status == this.STATUS_COMPLETED) {
        this.statusForm = {
          status: this.STATUS_COMPLETED,
        };
      } else {
        this.statusForm.status = this.STATUS_ADMIN_REFUSED;
        if (this.bankRefused) {
          this.statusForm.status = this.STATUS_BANK_REFUSED;
        }
      }
      // Afficher une confirmation avant de mettre à jour
      const confirm = await this.$confirm(
        this.$t("WALLET.CONFIRM_TRANSACTION_STATUS_UPDATE_MESSAGE"),
        this.$t("WALLET.CONFIRM_TRANSACTION_STATUS_UPDATE_TITLE"),
        {
          confirmButtonText: this.$t("WALLET.UPDATE_CONFIRM_BUTTON"),
          cancelButtonText: this.$t("WALLET.UPDATE_CANCEL_BUTTON"),
          type: "warning",
        }
      );

      if (confirm) {
        try {
          this.loading = true;
          const response = await this.$axios.put(
            `/elixio-wallet/transactions/${this.id}/status`,
            this.statusForm
          );
          if (response.data.success) {
            this.get();
            this.$notify({
              type: "success",
              message: this.$t("WALLET.TRANSACTION_STATUS_UPDATED"),
            });
            this.$emit("transactionStatusUpdated", status);
            this.loading = false;
          }
        } catch (error) {
          console.log(error);
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });
          this.loading = false;
        }
      }
    },
  },
};
</script>
