<template>
  <div class="">
    <input
      :value="address"
      class="form-control"
      :id="uniqueId"
      :placeholder="$t('COMMON.START_TYPING_CITY_REGION_COUNTRY')"
    />
  </div>
</template>
<script>
import formMixin from "@/mixins/form-mixin";

export default {
  layout: "DashboardLayout",
  name: "addresses-selector",

  components: {},

  mixins: [formMixin],

  props: {
    address: {
      type: String,
      required: false,
      default: ""
    },
    uniqueId: {
      type: String,
      required: false,
      default: 'address-input-unique'
    }
  },

  data() {
    return {
      components: {}
    };
  },

  created() {
  },

  methods: {
    onPlaceChanged(place) {
      let result = {};

      place?.address_components.forEach((item) => {
        item.types.forEach((type) => {
          result[type] = item.long_name;
        });
      });

      result = {
        ...result,
        address: place?.name,
        formatted_address: place?.formatted_address,
        latitude: place?.geometry?.location?.lat() ?? undefined,
        longitude: place?.geometry?.location?.lng() ?? undefined
      };

      this.getAddressData(result);
    },

    onFormChanged() {
    },

    getAddressData: function(addressData) {
      /*
       *country
       * state
       * city,
       * longitude
       * latitude
       * address
       */
      const addressValue = {
        city: addressData.locality
      };

      addressValue.country = addressData.country;
      addressValue.state =
        addressData?.administrative_area_level_1 !== "Québec"
          ? addressData.administrative_area_level_1
          : "Quebec";

      addressValue.address = addressData.address;
      addressValue.formatted_address = addressData.formatted_address;
      addressValue.longitude = addressData.longitude;
      addressValue.latitude = addressData.latitude;
      addressValue.zipcode = addressData.postal_code;

      this.$emit("addressInputChangeChanged", addressValue);
    }
  },
  mounted() {
    const geocoder = new google.maps.Geocoder();

    const input = document.getElementById(this.uniqueId);

    const options = {};

    const autocomplete = new google.maps.places.Autocomplete(input, options);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (place) {
        this.onPlaceChanged(place);
      } else {
        console.warn("No place selected");
      }
    });

    geocoder.geocode({ address: this.address }, (results, status) => {
      if (status === "OK" && results[0]) {
        this.components = results[0].address_components.reduce(
          (obj, component) => {
            obj[component.long_name] = component.short_name;
            return obj;
          },
          {}
        );
      }
    });
  },

  watch: {}
};
</script>